// this function is used to check if the iframe is same origin with the parent window
export const testSameOrigin = () => {
  try {
    window.parent.ssCheckSameOrigin = true; // a simple trick to check if the iframe is same origin with the parent window
    delete window.parent.ssCheckSameOrigin; // clean up

    return true;
  } catch (err) {
    return false;
  }
};
