import { useRouter } from 'next/router';
import { useMemo } from 'react';

export default function useAssertiveDisabled() {
  const router = useRouter();

  const isAssertivesDisabled = useMemo(
    () =>
      !router.isReady || router.query.assertives === 'false' || router.pathname.includes('embed'),
    [router.query.assertives, router.isReady, router.pathname],
  );

  return isAssertivesDisabled;
}
